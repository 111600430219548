import { ClientModel } from "../Models/ReduxModels/ClientDetails";
import { store } from "ReduxStore/index";
import moment from "moment";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import { Settings } from "Models/ReduxModels/Settings";
import UserDetails from "Models/ReduxModels/UserDetails";
import {
  onBoardingRoutesV2,
  onBoardingRoutesV3
} from "Configurations/Clients/vodafone/Routes";
import UserProfile from "Models/ReduxModels/UserProfileModel";
import { get } from "lodash";
import { FunctionMapper } from "Configurations/FunctionMapping";
import HttpClient from "./Http";
import local from "ReduxStore/localStorage";
import { EVENT_TYPE } from "Services/EventService";

import CMSImages, {
  IIntroScreenConfigurations
} from "Models/ReduxModels/CMSImages";

const customStore: any = store;
let clientName = process.env.REACT_APP_CLIENT_NAME?.trim() || "vodafone";

export function isEmptyObject(obj: Object) {
  if (!obj || Object.keys(obj).length <= 0) {
    return true;
  }
  return false;
}

export const getQueryParameterValue = (paramName: string) => {
  let query = window.location.search;
  let params = new URLSearchParams(query);
  let value = params.get(paramName);
  return encodeURIComponent(value ? value : "");
};

export const getQueryVariable = (variable: string) => {
  let query = window.location.search.substring(1);
  let vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return "";
};

export const getClientDetailsFromStore = key => {
  if (
    ClientModel.list(customStore.getState()) &&
    ClientModel.list(customStore.getState()).length &&
    ClientModel.list(customStore.getState())[0].props &&
    ClientModel.list(customStore.getState())[0].props.token
  ) {
    return ClientModel.list(customStore.getState())[0].props[key];
  }
};

export const getSettingsFromStore = key => {
  if (
    Settings.list(customStore.getState()) &&
    Settings.list(customStore.getState()).length &&
    Settings.list(customStore.getState())[0].props &&
    Settings.list(customStore.getState())[0].props.clientDetails &&
    Settings.list(customStore.getState())[0].props.clientDetails.tenantId
  ) {
    return Settings.list(customStore.getState())[0].props.clientDetails[key];
  }
};

// date conversion function
export const isoToLocalDateConversion = (date: string) => {
  if (!date) {
    return getCurrentTime();
  }
  return new Date(date);
};

export const getObject = (key: any, obj: any) => {
  return obj[key];
};

export const iterateHierarchy = (hierarachy: string, obj: any) => {
  let keysList = hierarachy.split(".");
  if (keysList && keysList.length) {
    let newObj = null;
    for (let i = 0; i < keysList.length; i++) {
      if (!newObj) {
        newObj = getObject(keysList[i], obj);
      } else {
        newObj = getObject(keysList[i], newObj);
      }
    }
    return newObj;
  }
  return null;
};

// export const iOSDevice = () => {
//   return (
//     [
//       "iPad Simulator",
//       "iPhone Simulator",
//       "iPod Simulator",
//       "iPad",
//       "iPhone",
//       "iPod"
//     ].includes(navigator.platform) ||
//     // iPad on iOS 13 detection
//     (navigator.userAgent.includes("Mac") && "ontouchend" in document)
//   );
// };

export const iOSDevice = () => {
  let deviceDetails = window?.navigator?.userAgent;
  const checkDevice = {
    "iPad Simulator": /iPad Simulator/.test(deviceDetails),
    "iPhone Simulator": /iPhone Simulator/.test(deviceDetails),
    "iPod Simulator": /iPod Simulator/.test(deviceDetails),
    iPad: /iPad/.test(deviceDetails),
    iPhone: /iPhone/.test(deviceDetails),
    iPod: /iPod/.test(deviceDetails)
  };
  const getDevice = Object.keys(checkDevice).some(
    device => checkDevice[device]
  );
  return (
    getDevice ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

export const getImageDetails = (url: string = "", altText: string = "") => {
  return { url, altText };
};

export const getValueFromHashMapArray = (data: any, key: string) => {
  let value: any;
  if (data?.length) {
    for (let index = 0; index < data.length; index++) {
      if (data[index].hasOwnProperty(key)) {
        value = data[index][key];
        break;
      }
    }
  } else return null;
  return value;
};

export const convertLocalDateToISO = date => {
  if (date) {
    let convertedDate = moment(date, "DD/MM/YYYY hh:mm:ss A").toISOString();
    return convertedDate;
  }
  return "";
};

export const checkChristmasMonth = (
  startDateStr?: string,
  endDateStr?: string
) => {
  const currentDate: any = moment(getCurrentTime());
  const startDate = moment(new Date(startDateStr || "")).set({
    hour: 0,
    minute: 0,
    second: 0
  });
  const endDate = moment(new Date(endDateStr || "")).set({
    hour: 23,
    minute: 59,
    second: 59
  });
  if (currentDate.isBetween(startDate, endDate, "seconds", "[])")) {
    return true;
  } else {
    return false;
  }
};

export const findEditedPermissions = (masterObj, currentObj) => {
  const objectKey = Object.keys(masterObj);
  const updatedValues = {};
  objectKey.forEach(key => {
    if (masterObj[key] === false) {
      updatedValues[key] = currentObj[key];
    }
  });
  return { ...updatedValues, call: null, email: null };
};

// convert hypen charcter to "\u2011" so that it support word-break
export const toNonHypenCharachter = str => {
  const text = str.split(" ");
  const newStr: any = [];
  text.map(word => {
    if (word.includes("-")) {
      newStr.push(word.replace(/-/g, "\u2011"));
    } else {
      newStr.push(word);
    }
  });
  return newStr.join(" ");
};

export const getCurrentTime = () => {
  let clienServerTimeDiff: any = window.localStorage.getItem(
    "clientServerTimeDiffrence"
  );
  let parsesdTimeDiffInMinutes = JSON.parse(clienServerTimeDiff);
  let currentTime = moment(new Date());
  let updatedDateTime: any;

  if (Math.sign(parsesdTimeDiffInMinutes) === 0) {
    updatedDateTime = currentTime;
  } else {
    updatedDateTime = moment(currentTime).add(
      parsesdTimeDiffInMinutes,
      "minutes"
    );
  }

  return new Date(updatedDateTime);
};

export const getClientName = () => {
  return process.env.REACT_APP_CLIENT_NAME
    ? process.env.REACT_APP_CLIENT_NAME.trim()
    : "";
};

export const saveDateTimeDiffrence = response => {
  if (response && response.attributes) {
    let clientDateTime = moment.utc();
    let serverDateTime = moment(response.attributes.currentDateTime);
    let timediff = serverDateTime.diff(clientDateTime, "minutes", true);
    window.localStorage.setItem(
      "clientServerTimeDiffrence",
      JSON.stringify(timediff)
    );
  }
};

export const computeTicks = (date: Date | undefined) => {
  if (!date) return 0;
  let clienServerTimeDiff: any = window.localStorage.getItem(
    "clientServerTimeDiffrence"
  );
  let parsedTimeDiffInMinutes = JSON.parse(clienServerTimeDiff);
  let updatedDateTime: any;

  if (Math.sign(parsedTimeDiffInMinutes) === 0) {
    updatedDateTime = new Date(date);
  } else {
    updatedDateTime = moment(new Date(date)).subtract(
      parsedTimeDiffInMinutes,
      "minutes"
    );
  }
  let ticks = new Date(updatedDateTime).getTime();
  return ticks;
};

export const setRulAIOps = () => {
  const userDetails = UserDetails.getInsatnce("")?.props || {};
  const settings = Settings.getInsatnce("")?.props || {};

  if (!window["_rulai"]) {
    window["_rulai"] = {
      domain: settings?.clientDetails?.rulaiDomain,
      pod: settings?.clientDetails?.rulaiPod,
      bot: settings?.clientDetails?.rulaiBotId || "",
      host: settings?.clientDetails?.rulaiHost,
      slot: {
        variables: [
          {
            name: "visitor.name",
            value: userDetails?.firstName || ""
          },
          {
            name: "visitor.phone",
            value: userDetails?.externalId || ""
          },
          {
            name: "visitor.email",
            value: userDetails?.email || ""
          }
        ]
      }
    };
  }
};

export const checkBannerMode = offer => {
  if (offer?.showFullAddImage === "true") {
    return true;
  }
  return false;
};
export const newOnboardingRoutePaths = [
  "landing",
  "sms",
  "location",
  "traffic",
  "profiling",
  "summary"
];
export const getNextNewOnBoardingRoute = currentRoute => {
  const currentPageIndex = newOnboardingRoutePaths.findIndex(
    element => element === currentRoute
  );

  if (currentPageIndex >= newOnboardingRoutePaths.length - 1) {
    return "home";
  }

  if (currentPageIndex !== -1) {
    const userProfile = UserProfile.getInsatnce("")?.props || {};
    const nextPageLabel = newOnboardingRoutePaths[currentPageIndex + 1];
    const nextPageObj = onBoardingRoutesV3[nextPageLabel];

    if (nextPageObj?.showAlways) {
      return nextPageObj.route;
    } else {
      if (get(userProfile, nextPageObj.condition) === false) {
        return nextPageObj.route;
      }
      return redirectToNextRoute(nextPageLabel);
    }
  }
  return "error"; ///
};

export const redirectToNextRoute = currentRoute => {
  const routeSequence = [
    "landing",
    "profile",
    "location",
    "traffic",
    "sms",
    "summary"
  ];

  const currentPageIndex = routeSequence.findIndex(
    element => element === currentRoute
  );

  if (currentPageIndex >= routeSequence.length - 1) {
    return "/home";
  }

  if (currentPageIndex !== -1) {
    const userProfile = UserProfile.getInsatnce("")?.props || {};
    const nextPageLabel = routeSequence[currentPageIndex + 1];
    const nextPageObj = onBoardingRoutesV2[nextPageLabel];

    if (nextPageObj?.showAlways) {
      return nextPageObj.route;
    } else {
      if (get(userProfile, nextPageObj.condition) === false) {
        return nextPageObj.route;
      }
      return redirectToNextRoute(nextPageLabel);
    }
  }
  return "/error-500"; ///
};

export const capitalizeFirstLetterOfString = str => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

/**
 * Tealium Code Start
 * Tealium Event Triggering Based on below code
 * Note - Don't Change below code otherwise tealium will not work
 */

// To get the all previousPages visited
export const previousPages: any = [];

// This counter is for saving and unsaving offer
// Once the counter is 2 -- then no event will raise for save & unsave offer
let countOffer_Favourite_Unfavourite = 0;
export const updateSaveUnsave_OfferCount = () => {
  return (countOffer_Favourite_Unfavourite += 1);
};

export const loadEvents = (previousPages: any = [], location: any = {}) => {
  if (
    typeof FunctionMapper[getClientName()].pageLoadHook == "function" &&
    ConstantMapping[getClientName()].SET_TEALIUM_TAGGING
  ) {
    const path = window.location.pathname.split("/")[1];
    const mapper = ConstantMapping[getClientName()].SET_TEALIUM_MAPPER;
    let currentPage = "";
    let obj: any = {};
    let currentEventName,
      currentPageSubSection,
      previousPageName,
      showView,
      eventAction,
      eventLabel,
      currentPageSub_SubSection,
      veryMe_OfferName,
      samePageEvent;

    if (location?.state && location?.state?.onLoadParams) {
      const {
        page_subsection = null,
        page_name = null,
        event_name = null,
        event_action = null,
        event_label = null,
        show_view = true,
        page_sub_subsection = null,
        same_page_event = false,
        veryme_offer_name = null
      } = location?.state && location?.state?.onLoadParams;
      currentEventName = event_name;
      currentPageSubSection = page_subsection;
      currentPage = page_name;
      showView = show_view;
      eventAction = event_action;
      eventLabel = event_label;
      currentPageSub_SubSection = page_sub_subsection;
      samePageEvent = same_page_event;
      veryMe_OfferName = veryme_offer_name;
    }

    if (!samePageEvent) {
      currentPage && previousPages.push(currentPage);

      if (mapper.TEALIUM_MAPPER[path]) {
        currentPage = mapper.TEALIUM_MAPPER[path];
        previousPages.push(mapper.TEALIUM_MAPPER[path]);
      } else if (mapper.ONBOARDINGTEALIUM_MAPPER[path]) {
        currentPage = mapper.ONBOARDINGTEALIUM_MAPPER[path];
        previousPages.push(mapper.ONBOARDINGTEALIUM_MAPPER[path]);
      }
    }

    // if (path === "summary") {
    //   previousPageName = previousPages[previousPages.length - 1];
    // } else {
    //   previousPageName = previousPages[previousPages.length - 2];
    // }

    if (
      previousPages[previousPages.length - 2] === currentPage &&
      !samePageEvent
    ) {
      previousPageName = previousPages[previousPages.length - 3];
    } else {
      previousPageName = previousPages[previousPages.length - 2];
    }

    obj = Object.assign(
      {},
      currentPageSubSection && { page_subsection: currentPageSubSection },
      currentEventName && { event_name: currentEventName },
      currentPage && { page_name: currentPage },
      previousPageName && { previous_page_name: previousPageName },
      eventAction && { event_action: eventAction },
      eventLabel && { event_label: eventLabel },
      currentPageSub_SubSection && {
        page_sub_subsection: currentPageSub_SubSection
      },
      veryMe_OfferName && { veryme_offer_name: veryMe_OfferName }
    );

    window.previousPage = previousPageName;

    FunctionMapper[getClientName()].pageLoadHook({
      tealium: {
        ...obj
      },
      showView: showView
    });
  }
};

export const manualParameterPass = (previousPages, obj) => {
  const extraParameter = Object.assign(
    {},
    {
      state: {
        onLoadParams: {
          ...obj
        }
      }
    }
  );
  loadEvents(previousPages, extraParameter);
};

/**
 * Tealium Code End
 */

export const getArticleOfferTextColor = offer => {
  let textColor = (offer?.additionalDetails || []).find(
    item => item.key === "card-text-color"
  );
  return textColor.value || "";
};

export const addExternalSupport = (dom: HTMLElement | null) => {
  const anchor = dom?.getElementsByTagName("a") || [];
  if (anchor?.length > 0) {
    for (const button of anchor as any) {
      if (!button.href.includes("external")) {
        let value = button.href;
        button.href = `external:${value}`;
        button.target = "_system";
      }
    }
  }
};

export const getSettings = async () => {
  const settingURL = process.env.PUBLIC_URL + "/settings.json";
  const httpClient = new HttpClient();
  try {
    let settingsResponse = await httpClient.get(settingURL);
    if (settingsResponse && settingsResponse.data) return settingsResponse.data;
    else throw new Error("Error fetching settings");
  } catch (err) {
    throw err;
  }
};

export const getImageUrls = (configList, key = "") => {
  let urls = "";
  for (const config of configList.appSectionList) {
    if (config.name === "IMAGES") {
      for (const image of config.configurationDataList) {
        if (image.name === key) {
          if (image.value.startsWith("/static/media/")) {
            urls = "";
          } else {
            urls = image.value;
          }
        }
      }
      break;
    }
  }
  return urls;
};

export const getIntroScreenConfig = configList => {
  const configKeys = {
    startDate: "start_time",
    endDate: "end_time",
    introScreenImageURL: "intro_screen_image",
    targetUsers: "targeted_users"
  };
  const configObj = {
    startDate: "",
    endDate: "",
    introScreenImageURL: "",
    targetUsers: ""
  };

  for (const config of configList.appSectionList) {
    if (config.name === "intro-screen") {
      for (const data of config.configurationDataList) {
        Object.keys(configObj).forEach(configKey => {
          const configVal = configKeys[configKey];
          if (data.name == configVal) {
            configObj[configKey] = data.value;
          }
        });
      }
      break;
    }
  }
  return configObj;
};

export const getLuckyDeepScreenConfig = configList => {
  const configKeys = {
    lucky_dip_intro_background_IMAGEURL: "lucky_dip_intro_background",
    lucky_dip_intro_animation_IMAGEURL: "lucky_dip_intro_animation",
    lucky_dip_play_animation_IMAGEURL: "lucky_dip_play_animation",
    lucky_dip_congrats_animation_IMAGEURL: "lucky_dip_congrats_animation"
  };
  const configObj = {
    lucky_dip_intro_background_IMAGEURL: "",
    lucky_dip_intro_animation_IMAGEURL: "",
    lucky_dip_play_animation_IMAGEURL: "",
    lucky_dip_congrats_animation_IMAGEURL: ""
  };

  for (const config of configList.appSectionList) {
    if (config.name === "IMAGES") {
      for (const data of config.configurationDataList) {
        Object.keys(configObj).forEach(configKey => {
          const configVal = configKeys[configKey];

          if (data.name === configVal) {
            configObj[configKey] = data.value;
          }
        });
      }
      break;
    }
  }

  return configObj;
};

export const getV2WScreenConfig = configList => {
  const configKeys = {
    v2w_full_overlay_popup_IMAGEURL: "v2w_full_overlay_popup",
    v2w_full_overlay_background_IMAGEURL: "v2w_full_overlay_background",
    v2w_half_overlay_popup_IMAGEURL: "v2w_half_overlay_popup"
  };
  const configObj = {
    v2w_full_overlay_popup_IMAGEURL: "",
    v2w_full_overlay_background_IMAGEURL: "",
    v2w_half_overlay_popup_IMAGEURL: ""
  };

  for (const config of configList.appSectionList) {
    if (config.name === "IMAGES") {
      for (const data of config.configurationDataList) {
        Object.keys(configObj).forEach(configKey => {
          const configVal = configKeys[configKey];
          if (data.name == configVal) {
            configObj[configKey] = data.value;
          }
        });
      }
      break;
    }
  }
  return configObj;
};

export const getInstantWinScreensConfig = configList => {
  const configKeys = {
    standard_creative_data: "standard-creative",
    festive_creative_data: "festive-creative",
    wimbledon_creative_data: "wimbledon-creative",
    iw_creative_three_data: "standard-creative-test",
    iw_creative_four_data: "standard-creative-test1"
  };
  const configObj = {
    standard_creative_data: {},
    festive_creative_data: {},
    wimbledon_creative_data: {},
    iw_creative_three_data: {},
    iw_creative_four_data: {}
  };
  let creativeList: any[] = [];
  configList?.appSectionList?.map(config => {
    if (config.type === "INSTANT_WIN_CREATIVES") {
      creativeList.push(config);
    }
  });
  for (let i = 0; i <= creativeList.length; i++) {
    for (const config of creativeList) {
      let keys = Object.keys(configObj);
      keys.map(configKey => {
        const configVal = configKeys[configKey];
        if (config.name === configVal) {
          configObj[configKey] = config.configurationDataList;
        }
      });
    }
  }
  return configObj;
};

export const getSTWScreenConfig = configList => {
  const configObj: {
    spin_to_win_background_img: string;
    spin_to_win_winner_screen_bg: string;
    spin_to_win_foreground_img: string;
    spin_to_win_loser_screen_bg: string;
    spin_to_win_brand_logos: string;
  } = {
    spin_to_win_background_img: "",
    spin_to_win_winner_screen_bg: "",
    spin_to_win_foreground_img: "",
    spin_to_win_loser_screen_bg: "",
    spin_to_win_brand_logos: ""
  };
  for (const config of configList.appSectionList) {
    if (config.name === "spin-to-win") {
      config?.configurationDataList.forEach(element => {
        if (element.type !== "COLOR")
          configObj[element?.name] = element?.value || "";
      });
    }
  }
  return configObj;
};

export const saveCMSImagesDataInRedux = cmsRespData => {
  const configKeys = ConstantMapping?.["vodafone"]?.IMAGES_DERIVE_FROM_CMS;
  const CMS_iwWinImage = getImageUrls(cmsRespData, configKeys?.iwWinImage);
  const CMS_iwLooseImage = getImageUrls(cmsRespData, configKeys?.iwLooseImage);
  const CMS_iwIntroImage = getImageUrls(cmsRespData, configKeys?.iwIntroImage);
  const CMS_introScreenConfigurations = getIntroScreenConfig(cmsRespData);
  const CMS_LuckyDeepImageConfigurations =
    getLuckyDeepScreenConfig(cmsRespData);
  const CMS_visitToWinConfigurations = getV2WScreenConfig(cmsRespData);
  const CMS_iw_creativesConfigurations =
    getInstantWinScreensConfig(cmsRespData);
  const CMS_spinToWinConfigurations = getSTWScreenConfig(cmsRespData);
  new CMSImages({
    id: "",
    CMS_iwWinImage,
    CMS_iwLooseImage,
    CMS_iwIntroImage,
    CMS_introScreenConfigurations,
    CMS_LuckyDeepImageConfigurations,
    CMS_visitToWinConfigurations,
    CMS_iw_creativesConfigurations,
    CMS_spinToWinConfigurations
  }).$save();
};

export const getCMSDrivenImageURL = (cmsdata?) => {
  /* {"id":"intro-screen","key":"intro-screen","value":[{"name":"end_time","type":"TEXT","value":"2022-01-15T12:35:00.000Z"},{"name":"intro_screen_image","type":"IMAGE","value":"https://assets.mvp.dev.affinionservices.com/content-artifacts/694a831f464d4877a59ab4df8773f8a5_home_primer_christmas.jpg"},{"name":"start_time","type":"TEXT","value":"2022-01-10T12:35:08.299Z"},{"name":"targeted_users","type":"TEXT","value":"Consumer contract,Consumer postpay,PAYG,Prepay,Enterprise,Unknown"}]}
   */
  let image = "";
  if (cmsdata) {
    let data = cmsdata.value;
    data.forEach(item => {
      if (item.name === "intro_screen_image" && item.type === "IMAGE") {
        image = item.value;
      }
    });
  }
  return image;
};

export const getIntroScreenToShow = (
  appAccessLevel,
  isVisitToWin,
  isCMSDrivenImage,
  isConvergedUser,
  currentCustomerType,
  introScreenConfigurations?: IIntroScreenConfigurations
): string => {
  // if (isVMRLiteUser || (appAccessLevel === "PAYG_LITE") &&!localStorage.getItem("VMRLITE")) {
  //   return "VMRLITE";
  // } else if (isConvergedUser&&!localStorage.getItem("CONVERGED")) {
  //   return "CONVERGED";
  // } else if (isCMSDrivenImage&&shouldCMSDrivenShowIntroScreen(introScreenConfigurations,currentCustomerType)&&!localStorage.getItem("CMSDRIVEN")) {
  //   return "CMSDRIVEN";
  // } else

  if (isVisitToWin) {
    // if (isCMSDrivenImage&&shouldCMSDrivenShowIntroScreen(introScreenConfigurations,currentCustomerType&&!localStorage.getItem("CMSDRIVEN"))) {
    //   return "CMSDRIVEN";
    // }
    return "VISITTOWIN";
  } else {
    return "DEFAULT";
  }
};

const shouldCMSDrivenShowIntroScreen = (
  introScreenConfigurations?: IIntroScreenConfigurations,
  currentCustomerType?
): boolean => {
  const introScreenExists =
    introScreenConfigurations?.introScreenImageURL || "";
  const isTargetedForThisUser = matchesUserCriteria(
    currentCustomerType,
    introScreenConfigurations
  );
  const isExpired = isCMSDrivenIntroScreenExpired(introScreenConfigurations);
  if (introScreenExists && isTargetedForThisUser && !isExpired) return true;
  else return false;
};

const isCMSDrivenIntroScreenExpired = (
  introScreenConfigurations?: IIntroScreenConfigurations
): boolean => {
  const startDateStr = introScreenConfigurations?.startDate || "";
  const endDateStr = introScreenConfigurations?.endDate || "";
  const currentDate: any = moment(getCurrentTime());
  const startDate = moment(new Date(startDateStr || ""));
  const endDate = moment(new Date(endDateStr || ""));
  if (currentDate.isBetween(startDate, endDate, "minutes", "[])")) {
    return false;
  } else {
    return true;
  }
};

const matchesUserCriteria = (
  currentCustomerType,
  introScreenConfigurations?: IIntroScreenConfigurations
): boolean => {
  let targetedUsers = introScreenConfigurations?.targetUsers?.split(",") || [];
  targetedUsers = targetedUsers.map(item => item.toLowerCase().trim());
  if (currentCustomerType)
    return targetedUsers.includes(currentCustomerType.toLowerCase().trim());
  else return false;
};

export const getNextStartBoardingEvent = (
  currentOnboardingPage,
  totalOnboardingPages
) => {
  const currentPage = totalOnboardingPages[currentOnboardingPage];
  const totalPages = totalOnboardingPages.length;
  const nextPage = currentOnboardingPage + 1;
  let eventName = "";
  if (nextPage <= totalPages) {
    switch (totalOnboardingPages[nextPage].toLowerCase()) {
      case "sms":
        eventName = EVENT_TYPE.ONBOARDING_SMS_PERMISSION_LANDED;
        break;
      case "location":
        eventName = EVENT_TYPE.ONBOARDING_LOCATION_PERMISSION_LANDED;
        break;
      case "traffic":
        eventName = EVENT_TYPE.ONBOARDING_TRAFFIC_PERMISSION_LANDED;
        break;
      case "profiling":
        eventName = EVENT_TYPE.ONBOARDING_PROFILE_PERMISSION_LANDED;
        break;
      case "summary":
        eventName = EVENT_TYPE.ONBOARDING_SUMMARY_LANDED;
        break;
      default:
        break;
    }
  }
  return eventName;
};
