import { BaseModel } from "ReduxStore/index";

export interface IIntroScreenConfigurations {
  startDate: string;
  endDate: string;
  targetUsers: string;
  introScreenImageURL: string;
}

export interface ICMSLuckyDeepImageConfigurations {
  lucky_dip_intro_background_IMAGEURL: string;
  lucky_dip_intro_animation_IMAGEURL: string;
  lucky_dip_play_animation_IMAGEURL: string;
  lucky_dip_congrats_animation_IMAGEURL: string;
}
export interface IVisitoWinCongigurations {
  v2w_full_overlay_popup_IMAGEURL: string;
  v2w_full_overlay_background_IMAGEURL: string;
  v2w_half_overlay_popup_IMAGEURL: string;
}

export interface IInstantWinCreativesConfigurations {
  standard_creative_data: any;
  festive_creative_data: any;
  wimbledon_creative_data: any;
  iw_creative_three_data: any;
  iw_creative_four_data: any;
}

export interface ISpinToWinScreenConfigurations {
  spin_to_win_background_img: string;
  spin_to_win_winner_screen_bg?: string;
  spin_to_win_foreground_img: string;
  spin_to_win_loser_screen_bg?: string;
  spin_to_win_brand_logos?: string;
}

export interface ICMSImagesDetails {
  CMS_iwWinImage?: string;
  CMS_iwLooseImage?: string;
  CMS_iwIntroImage: string;
  CMS_introScreenConfigurations?: IIntroScreenConfigurations;
  CMS_LuckyDeepImageConfigurations?: ICMSLuckyDeepImageConfigurations;
  CMS_visitToWinConfigurations?: IVisitoWinCongigurations;
  CMS_iw_creativesConfigurations?: IInstantWinCreativesConfigurations;
  CMS_spinToWinConfigurations?: ISpinToWinScreenConfigurations;
}

export default class CMSImages extends BaseModel<ICMSImagesDetails> {
  static resource = "CMSImages";
}
